import React, { useState, useEffect, useRef } from "react"
import Styles from "../components/target.module.scss"

const TargetFadeInDelay = ({ children }) => {
  const target = useRef(null)
  const [classNames, setClassNames] = useState(Styles.scroll)
  useEffect(() => {
    const cb = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //クラスの付け替え
          setClassNames(Styles.scrollInDelay)
          io.unobserve(entry.target)
        } else {
          //クラスの付け替え
          setClassNames(Styles.scroll)
        }
      })
    }
    const options = {
      root: null, //ビューポートと交差
      rootMargin: "-15% 0px", //上下内側に-10%の地点で処理を実行する
    }
    const io = new IntersectionObserver(cb, options)
    io.observe(target.current)
  }, [])
  return (
    <div ref={target} className={classNames}>
      {children}
    </div>
  )
}

export default TargetFadeInDelay
